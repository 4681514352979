import { signal, effect } from '@preact/signals'
import { navigate } from 'wouter-preact/use-location'

import { ListStore } from '../../src-lib/stores/listStore'

const SearchState = ({ api }) => {
  const term = signal('')
  const terms = ListStore({ unique: true })
  const results = ListStore()

  const list = async terms => {
    const items = await api.search.query(terms)
    results.removeAll()
    results.add(items)
  }

  const url = (terms) => {
    let url = '/'
    url = terms.length > 0 ? `/search/${terms.join(',')}` : url
    navigate(url)
  }

  const onTermsChange = (terms) => {
    url(terms)
    list(terms)
  }
  
  effect(() => onTermsChange(terms.items.value))

  return {
    term,
    terms,
    results
  }
}

export { SearchState }
