import { render } from 'preact'
import { useRoute, Route, Switch } from "wouter-preact"

import { API } from './api'
import { State } from './state'
import { Index } from './pages/index'

const api = API()
const state = State({ api })

const prerender = async () => {
  await Promise.all([
    api.search.index()
  ])
}

const App = () => {
  return (
    <div class="absolute inset-0">
      <div class="r-fix-scrollbar flex flex-col h-full">
        <Switch>
          <Route path="/"><Index state={state} /></Route>
          <Route path="/search/:terms"><Index state={state} /></Route>          
          <Route>404, Not Found!</Route>
        </Switch>
      </div>
    </div>
  )
}

prerender()
render(<App />, document.body)
