const AppFooter = props => {
  return (
    <footer>
        <p class="text-center text-xs text-gray-500 pb-4">
          Recipes by <a href="https://www.hellofresh.com/recipes" target="_blank" class="font-bold text-gray-600">HelloFresh</a> | 
          Made by <a href="https://github.com/brihter" target="_blank" class="font-bold text-gray-600">Bostjan Rihter</a>
        </p>
    </footer>
  )
}

export { AppFooter }
