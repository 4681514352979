import { signal, computed } from '@preact/signals'

const ListStore = (cfg = {}) => {
  cfg = Object.assign({
    items: [],
    unique: false
  }, cfg)

  const items = signal(cfg.items)
  const itemsLookup = new Set(cfg.items)
  const hasItems = computed(() => items.value.length > 0)

  const addOne = (item) => {
    if (itemsLookup.has(item)) {
      return
    }
    
    items.value = [...items.value, item]
    itemsLookup.add(item)
  }

  const add = (items) => {
    if (typeof items !== 'object') {
      items = [items]
    }

    if (items.length === 0) {
      return
    }

    items.forEach(addOne)
  }

  const remove = (item) => {
    const index = items.value.indexOf(item)
    items.value.splice(index, 1)
    items.value = [...items.value]
    itemsLookup.delete(item)
  }

  const removeAll = () => {
    const toRemove = Array.from(items.value) // clone
    if (toRemove.length === 0) {
      return
    }
    toRemove.forEach(remove)
  }

  const has = (item) => {
    return itemsLookup.has(item)
  }

  return {
    items,
    hasItems,

    add,
    remove,
    removeAll,
    has
  }
}

export { ListStore }
