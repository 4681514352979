import { AppFooter } from '../elements/appFooter'

import { Search } from '../elements/search'
import { SearchResults } from '../elements/searchResults'

const Index = props => {
  return (
    <>
      <main class="mb-auto mx-auto w-full max-w-2xl px-4">
        <Search state={props.state} />
        <SearchResults state={props.state} />
      </main>
      <AppFooter />
    </>
  )
}

export { Index }
