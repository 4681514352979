import { SearchState } from './searchState'

const State = ({ api }) => {
  const searchState = SearchState({ api })
  
  return {
    search: searchState
  }
}

export { State }
