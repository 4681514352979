const SearchTerm = props => {
  const state = props.state.search

  const onClick = () => state.terms.remove(props.value)

  return (
    <span class="inline-flex items-center rounded bg-emerald-100 py-1 pl-2 pr-0.5 mr-1 text-xs font-medium text-emerald-700">
      {props.value}
      <button
        type="button"
        onClick={onClick}
        class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded text-emerald-400 hover:bg-emerald-200 hover:text-emerald-500 focus:bg-emerald-500 focus:text-white focus:outline-none"
      >
        <span class="sr-only">Remove</span>
        <svg
          class="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path
            stroke-linecap="round"
            stroke-width="1.5"
            d="M1 1l6 6m0-6L1 7"
          />
        </svg>
      </button>
    </span>
  )
}

const Search = props => {
  const state = props.state.search

  const onInput = e => {
    state.term.value = e.target.value
  }

  const onKeyPress = e => {
    if (e.key !== 'Enter') return
    state.terms.add(state.term.value)
    state.term.value = ''
  }

  return (
    <div class="mt-8 mb-8 divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-2xl">
      <div class="relative">
        <svg
          class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clip-rule="evenodd"
          />
        </svg>
        <input
          type="text"
          class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
          placeholder="Search ..."
          autofocus
          value={state.term.value}
          onInput={onInput}
          onKeyPress={onKeyPress}
        />
      </div>
      {state.terms.items.value.length > 0 && (
        <p class="px-2 py-2 text-sm">
          {state.terms.items.value.map(term => {
            return <SearchTerm state={props.state} value={term} />
          })}
        </p>
      )}
    </div>
  )
}

export { Search }
