import lunr from 'lunr'

const SearchApi = () => {
  let searchIndex

  const load = async (file) => {
    const data = await fetch(file)
    return await data.text()
  }

  const index = async () => {
    if (searchIndex) {
      return
    }
    
    const loadRecipes = async () => {
      let data = {}
      data = await load('/data/recipe.json')
      data = JSON.parse(data)

      let items = []
      items = data.items

      items.forEach(i => localStorage.setItem(`recipe-${i.i}`, JSON.stringify({ n: i.n, d: i.d })))
    }

    const loadRecipeIndex = async () => {
      let data = {}
      data = await load('/data/recipe-index.json')
      data = JSON.parse(data)

      localStorage.setItem(`recipe-index`, JSON.stringify(data.index))
      return data.index
    }

    const [, index] = await Promise.all([
      loadRecipes(),
      loadRecipeIndex()
    ])

    searchIndex = lunr.Index.load(index)
  }

  const intersect = (a, b) => {
    const s1 = new Set(a)
    const s2 = new Set(b)
    const result = new Set([...s1].filter(x => s2.has(x)))

    return Array.from(result)
  }

  const toResult = (id) => {
    let record = {}
    record = localStorage.getItem(`recipe-${id}`)
    record = JSON.parse(record)

    return {
      id,
      name: record.n,
      description: record.d
    }
  }

  const toQuery = async (term) => searchIndex
    .search(`${term}*`)
    .map(r => r.ref)

  const query = async terms => {
    if (!terms || terms.length === 0) {
      return []
    }

    if (!searchIndex) {
      await index()
    }

    const queryTasks = terms.map(toQuery)
    const queryResults = await Promise.all(queryTasks)

    let ids = []
    ids = queryResults.reduce(intersect, queryResults[0] || [])
    ids = ids.map(toResult)

    return ids
  }

  return {
    index,
    query
  }
}

export { SearchApi }
