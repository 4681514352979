const SearchResults = props => {
  const state = props.state.search

  return (
    <div class="my-8 divide-y divide-gray-100 rounded-md bg-white shadow-2xl">
      {/* no results */}
      {state.terms.hasItems.value && !state.results.hasItems.value && (
        <p class="p-4 text-sm text-gray-500">No recipes found.</p>
      )}
      {/* no results END */}
      {/* results */}
      {state.results.hasItems.value && (
        <ul role="list" class="divide-y divide-gray-100 text-sm">
          {state.results.items.value.map(result => {
            return (
              <li class="flex p-4">
                <div class={'h-10 w-10 rounded r r-'+result.id}></div>
                <div class="ml-4 min-w-0">
                  <p class="font-medium text-gray-900">{result.name}</p>
                  <p class="text-gray-500 truncate">{result.description}</p>
                </div>
              </li>
            )
          })}
        </ul>
      )}
      {/* results END */}
    </div>
  )
}

export { SearchResults }
